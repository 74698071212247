import request from '@/utils/request'

export function questionInfo(params) {
	return request({
		url: '/question/drug',
		method: 'post',
		data: params
	})
}

export function questionAnswer(params) {
	return request({
		url: '/question/submit/answer',
		method: 'post',
		data: params
	})
}

export function questionDetail(params) {
	return request({
		url: '/question/check/info',
		method: 'post',
		params
	})
}