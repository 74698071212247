<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"> </div>
				</div>
			</div>
		</div>
		<div class="question-content" v-if="question">
			<div class="title">{{question.content}}</div>
			<el-form ref="answer" :model="answer" label-width="80px" v-if="answer" :rules="rules">
				<el-form-item label="姓名" prop="name">
					<el-input v-model="answer.name" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="身份证号" prop="card">
					<el-input v-model="answer.card" placeholder="请输入身份证号 "></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phone">
					<el-input v-model="answer.phone" maxlength="11" placeholder="请输入联系电话"></el-input>
				</el-form-item>
				<el-form-item label="处方单" prop="file">
					<ImageUpload v-model="answer.file" :limit="5"></ImageUpload>
				</el-form-item>
			</el-form>
			<el-form class="question" ref="role2Form" :rules="rules2" :model="role2Form" label-width="80px"
				v-if="role2Form">
				<el-form-item :label="item.sort+''" :prop="item.isRequired==1?item.id+'':''"
					v-for="(item,index) in questionSub" :key="index">
					<div>{{item.title}}</div>
					<el-input v-if="item.aType=='input'" v-model="role2Form[item.id]"
						@input="val=>changeValue(val,item.id,item.aType)" placeholder="请输入"></el-input>

					<el-select v-if="item.aType=='select'" v-model="role2Form[item.id]" placeholder="请选择"
						@input="val=>changeValue(val,item.id,item.aType)">
						<el-option v-for="(opItem,i) in option[item.id]" :label="opItem.name" :key="i"
							:value="opItem.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="submit()"
						:loading="loading">{{loading?'正在提交':'提&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;交'}}</el-button>
				</el-form-item>
			</el-form>
		</div>
		<Footer></Footer>

	</div>
</template>

<script>
	import {
		questionAnswer,
		questionInfo,
		questionDetail
	} from '@/api/question/question'
	export default {
		data() {
			return {
				name: '',
				answer: {},
				qanswer: {},
				answerSub: [],
				option: [],
				questionSub: [],
				question: {},
				rules: {
					name: {
						required: true,
						message: '请输入姓名'
					},
					phone: [{
							required: true,
							message: '请输入手机号'
						},
						{
							pattern: '^1\\d{10}$',
							message: '手机号格式不正确'
						}
					],
					card: [{
						required: true,
						message: '请输入身份证号'
					}, {
						pattern: '\\d{17}[\\d|x|X]|\\d{15}',
						message: '身份证号格式不正确'
					}]
				},
				rules2: {},
				role2Form: {},
				param: null,
				cartIds: [],
				loading: false
			};
		},
		created() {
			this.cartIds = this.$route.params.cartIds
			this.param = JSON.parse(this.$route.params.param)
			this.getQuestion()
		},
		methods: {
			changeValue(e, id, type) {
				this.$forceUpdate()
				if (type == 'input') {
					this.answerSub.forEach(sub => {
						if (sub.qId == id) {
							sub.value = e
						}
					})
				} else if (type == 'select') {
					this.answerSub.forEach(sub => {
						if (sub.qId == id) {
							sub.value = e
						}
					})
				}
			},
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			getQuestion() {
				questionInfo({}).then(data => {
					if (null != data.data) {
						questionDetail({
							id: data.data.id
						}).then(res => {
							this.question = res.data.question
							this.answer = {
								id: null,
								qId: this.question.id,
								name: '',
								phone: '',
								sex: 1,
								card: '',
								file:[]
							}
							this.questionSub = res.data.questionSub
							var rules = {}
							this.questionSub.forEach(sub => {
								if (sub.isRequired == 1) {
									if (sub.aType == 'input') {
										rules[sub.id + ''] = [{
											required: true,
											validator: (rule, value, callback) => {
												if (value) {
													callback()
												} else {
													callback(new Error('请完成第' + sub
														.sort + '题'))
												}
											}
										}]
									} else {
										rules[sub.id + ''] = [{
											required: true,
											validator: (rule, value, callback) => {
												if (value) {
													callback()
												} else {
													callback(new Error('请完成第' + sub
														.sort + '题'))
												}
											}
										}]
									}
								}
							})
							this.rules2 = rules
							this.option = res.data.option
							var answerSub = res.data.answerSub
							var list = []
							this.questionSub.forEach(item => {
								var aSub = {
									id: '',
									qId: item.id,
									ansId: this.answer.id,
									value: ''
								}
								answerSub.forEach(sub => {
									if (sub.qId == item.id) {
										aSub = sub
									}
								})
								list.push(aSub);
								this.role2Form[item.id + ''] = aSub.value
							})
							this.answerSub = list
						})
					}
				})
			},
			submit() {
				if (this.loading) {
					return
				}
				this.$refs.answer.validate((valid) => {
					if (valid) {
						for (let key in this.rules2) {
							if (this.rules2[key][0].required) {
								if (!this.role2Form[key]) {
									this.$message({
										type: 'error',
										message: '请填写完整信息'
									});
									return
								}
							}
						}
						if(this.answer.file.length==0){
							this.$message({
								type: 'error',
								message: '请上传处方单'
							});
							return
						}
						this.loading = true
						var data = {
							answer: this.answer,
							list: this.answerSub
						}
						questionAnswer(data).then(res => {
							var id = res.data
							this.loading = false
							this.$router.push({
								name: 'orderConfirm',
								params: {
									param: JSON.stringify(this.param),
									cartIds: this.cartIds,
									answerId: id
								}
							})
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			}
		},
	};
</script>

<style lang='scss'>
	.question-content {
		width: 80%;
		margin-left: 10%;

		.title {
			text-align: center;
			font-size: 18px;
			font-weight: bold;
			color: red;
			padding: 20px 0;
		}

		.question .el-form-item__error {
			display: none !important;
		}

		.question .el-input__inner {
			border: 1px solid #DCDFE6 !important;
		}
	}
</style>